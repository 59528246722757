<template>
  <div>
    <page-title :title="`View Performancebond`" />
    <div class="row row-sm">
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <div id="bid-bond-template" v-html="performancebond"></div>
          </div>
        </div>
      </div>
			<div class="col-md-3" v-if="is_loaded">
        <a href="#" @click.prevent="downloadPreview" v-if="$can('performancebonds.download-preview')" class="btn btn-secondary btn-block mb-3">Download this preview</a>
				<div class="card">
					<div class="card-body">
						<h4>Status</h4>
            <div :class="`alert alert-${details.status == 'processing' ? 'warning' : details.status == 'confirmed' ? 'success' : 'danger'}`">{{ details.status }}</div>
            <div v-if="details.status == 'confirmed'" class="mt-3">
              <a :href="details.document" target="_blank" class="btn btn-secondary btn-sm"><i class="lni-download mr-2"></i> Download</a>
            </div>
					</div>
				</div>
        <div class="card" v-if="$can('performancebonds.upload')">
          <div class="card-body">
            <h4>Upload Performancebond</h4>
            <form @submit.prevent="upload">
              <div class="form-group">
                <label>Select File</label>
                <input type="file" class="" ref="performancebond" id="select-file" required>
              </div>
              <div class="form-group text-right">
                <button class="btn btn-primary btn-sm"><i class="lni-upload mr-2"></i>Upload</button>
              </div>
            </form>
          </div>
        </div>
			</div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      performancebond: "",
      is_loaded: false,
      details: {}
    };
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios
        .get(
          `/api/v1/dashboard/performancebonds/${this.$route.params.id}/view`
        )
        .then(response => {
          if(response.data == 0) {
            this.$loader.stop()
            this.$router.push({ name: 'performancebonds.payment' })
          } else {
            this.performancebond = response.data;
            this.fetchDetails()
            this.$loader.stop()
          }
        });
    },

    fetchDetails() {
      this.is_loaded = false
      this.$axios.get(`/api/v1/dashboard/performancebonds/${this.$route.params.id}`).then(response => {
        this.details = response.data
        this.is_loaded = true
      })
    },

    upload() {
      this.$loader.start()

      var data = new FormData();
      data.append("performancebond", this.$refs.performancebond.files[0]);

      this.$axios.post(`/api/v1/dashboard/performancebonds/${this.$route.params.id}/document`, data).then(() => {
        this.$loader.stop()
        this.fetchDetails()
      })
    }
  }
};
</script>